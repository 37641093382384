import { Button, Flex, IconLoading, Modal, Paragraph, Text } from "@powerledger/ui-component-lib";
import { Field, Form, Formik, useFormikContext } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { AppCleaveTypes, CleaveInput } from "../cleave-input";
import { SeparatorLayout } from "../separator-layout";
import {
  ExportQuanityProps,
  FormValues,
  ManagedCommoditiesExportModalProps,
  STEPS,
} from "./managed-commodities-export-modal.types";

const ExportQuanity: FC<ExportQuanityProps> = ({ currentStep }) => {
  const { errors } = useFormikContext<FormValues>();
  return (
    <>
      <Field
        name="exportVolume"
        component={CleaveInput}
        type={AppCleaveTypes.Quantity}
        disabled={currentStep === STEPS.EXPORT_SUBMIT}
      />
      <Paragraph
        sx={{
          fontSize: 0,
          color: "error.500",
        }}
      >
        {errors?.exportVolume}
      </Paragraph>
    </>
  );
};

export const ManagedCommoditiesExportModalView = ({
  visible,
  closeModal,
  exportData,
  onExportSubmit,
  currentStep,
  modalTitle,
  handleStepChange,
  onNavigate,
}: ManagedCommoditiesExportModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal visible={!!visible} onCancel={closeModal} title={modalTitle}>
      {currentStep === STEPS.EXPORT_SUCCESS ? (
        <Flex
          sx={{
            justifyContent: "flex-end",
            gap: 2,
            mt: 4,
          }}
        >
          <Button variant="error" onClick={closeModal}>
            {t("Close")}
          </Button>
          <Button
            disabled={false}
            onClick={() => {
              closeModal();
              onNavigate();
            }}
          >
            {t("View Requests here")}
          </Button>
        </Flex>
      ) : (
        <Formik
          initialValues={{ exportVolume: 0 }}
          validationSchema={Yup.object().shape({
            exportVolume: Yup.number()
              .required(t("Enter valid amount"))
              .positive(t("Enter valid amount"))
              .max(exportData.availableVolume || 0, t("You don't have enough quantity available."))
              .min(1, t("Enter valid amount")),
          })}
          onSubmit={async (values, action) => onExportSubmit && onExportSubmit(values, action)}
        >
          {({ handleSubmit, isValid, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <SeparatorLayout
                contentStyle={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "30%",
                }}
                data={{
                  Vintage: exportData?.vintage,
                  Serial: exportData?.serial,
                  "Available Volume": exportData?.availableVolume,
                }}
              />
              <Flex
                sx={{
                  mt: 4,
                  width: "30%",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Text
                  sx={{
                    fontSize: 2,
                    fontWeight: "bold",
                  }}
                >
                  {t("Export Quantity")}
                </Text>
                <ExportQuanity currentStep={currentStep} />
              </Flex>
              <Flex
                sx={{
                  justifyContent: "flex-end",
                  gap: 2,
                  mt: 4,
                }}
              >
                {currentStep === STEPS.EXPORT_FORM ? (
                  <Button variant="error" onClick={closeModal} type="button">
                    {t("Cancel")}
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    sx={{ color: "accentLight", textDecoration: "underline", textUnderlineOffset: 2 }}
                    onClick={() => handleStepChange && handleStepChange(STEPS.EXPORT_FORM)}
                    type="button"
                  >
                    {t("Go back")}
                  </Button>
                )}
                <Button disabled={!isValid || isSubmitting} type="submit">
                  {t(currentStep === STEPS.EXPORT_FORM ? "Next" : "Confirm")}
                </Button>
                {isSubmitting && <IconLoading size="small" />}
              </Flex>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
