import { IconInfo, IconWarning, Table } from "@powerledger/ui-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";
import { Box, Button, Flex, Paragraph } from "theme-ui";

import { AttributesTooltip, LoadingOverlayIndicator, SeparatorLayout } from "@/app/components";
import { EligibilityValueFormatter } from "@/app/components/eligibility-value-formatter";
import { PageHeader } from "@/app/components/page-header";
import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { StatusInfo } from "@/app/components/retirement-export-requests-common";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { getCommonRecTableColumns } from "@/app/lib/common-rec-table-columns";
import { CertificationAndEliglibilityType } from "@/app/lib/format-attributes";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { volumeFormatter } from "@/app/lib/volume-formatter";
import {
  CommoditySerial,
  CommodityTransactionStatus,
  OrderPosition,
  RetirementStatus,
} from "@/app/types/generated/graphql";

import {
  formatRetirementReport,
  FormattedRetirementSummary,
  getRetirementReason,
  getRetirementType,
} from "../common/helpers";
import { RetirementStatusChip } from "../retirement-requests-list/retirement-requests-table/retirement-requests-table";
import { RetirementRequestDetailsViewProps } from "./retirement-request-details.types";

export const RetirementRequestDetailsView: React.FC<RetirementRequestDetailsViewProps> = ({
  data,
  loading,
  markAsDone,
  goBack,
  refetchDetails,
  recOptions,
  rejectRequest,
  allowRejection,
}) => {
  const { t } = useTranslation();

  const columns: Column<FormattedRetirementSummary>[] = useMemo(
    () => [
      {
        Header: t("Vintage"),
        accessor: "vintage",
        Cell: ({ value }: { value: string }) => {
          return <TableValueWrapper value={value} />;
        },
        disableSortBy: true,
      },
      {
        Header: t("Project"),
        accessor: "project",
        Cell: ({ value }: { value: string }) => {
          return <TableValueWrapper value={t(value) || ""} />;
        },
        disableSortBy: true,
      },
      {
        Header: t("Eligibility"),
        accessor: "eligibilities",
        Cell: ({ value }: { value: CertificationAndEliglibilityType[] }) => {
          return <EligibilityValueFormatter certificationsAndEligibilities={value} position={OrderPosition.Ask} />;
        },
        disableSortBy: true,
      },
      ...(getCommonRecTableColumns(t, recOptions) as Column<FormattedRetirementSummary>[]),
      {
        Header: t("Commencement Date (COD)"),
        accessor: "commencementOperationDate",
        Cell: ({ value }: { value: string }) => {
          const commencementDate = value ?? "-";
          return <TableValueWrapper value={commencementDate} />;
        },
        disableSortBy: true,
      },
      {
        Header: t("Serial Numbers"),
        accessor: "commoditySerial",
        Cell: ({ value }: { value: CommoditySerial }) => {
          return `${value.serialPrefix}-${value.serialFrom} ${t("to")} ${value.serialTo}`;
        },
        disableSortBy: true,
      },
      {
        Header: t("Status"),
        accessor: "status",
        disableSortBy: true,
        Cell: ({ value, row }: { row: Row<FormattedRetirementSummary>; value: CommodityTransactionStatus }) => {
          return <RetirementStatusChip status={value} statusMessage={row.original.statusMessage} id={row.id} />;
        },
      },
      {
        Header: t("Quantity"),
        accessor: "volume",
        Cell: ({ value }: { value: number }) => {
          return <TableValueWrapper value={volumeFormatter(value)} />;
        },
        disableSortBy: true,
      },
      {
        Header: t("Action"),
        id: "action",
        Cell: ({ row }: { row: Row<FormattedRetirementSummary> }) => {
          return (
            // admins can try retiring the failed transaction by contacting Registries and can turn the transaction to success
            row.original.status !== CommodityTransactionStatus.Success &&
            row.original.retirementStatus !== RetirementStatus.Rejected && (
              <Button
                key={row.original.id + row.index + 1}
                variant="pill.compactSecondary"
                sx={{
                  height: 27,
                }}
                onClick={() => markAsDone(row.original.id, row.original.commoditySerial)}
              >
                {t("Mark as Done")}
              </Button>
            )
          );
        },
        disableSortBy: true,
      },
    ],
    [t, recOptions, markAsDone],
  );

  if (loading || !data) {
    return <LoadingOverlayIndicator />;
  }

  const message = data.statusMessage ?? `-`;

  const isEveryCommodityTransactionsProcessed =
    data.commodityDetails?.length > 0 &&
    data.commodityDetails.every(
      (commodityDetail) =>
        Array.isArray(commodityDetail.commodityTransactions) && commodityDetail.commodityTransactions.length > 0,
    );

  return (
    <>
      <PageHeader
        goBack={goBack}
        title={`Retirement Request - ${data.retirementNumber}`}
        extraContent={
          <Flex
            sx={{
              alignItems: "center",
              position: "absolute",
              right: 0,
            }}
          >
            <RefreshIconButton fetchData={refetchDetails} loading={loading} />
          </Flex>
        }
      />
      <Box sx={{ mt: 5 }}>
        <SeparatorLayout
          contentStyle={{
            width: "18%",
          }}
          headingStyle={{
            color: "textDark",
          }}
          data={{
            Status: <RetirementStatusChip id={data.id} status={data.status} statusMessage={""} />,
            "Retirement Type": getRetirementType(data.retirementType, data.retirementCategory),
            "Retirement Reason": getRetirementReason(data.retirementDetails),
            "Retirement Notes": data.retirementDetails.notes,
            "Status Info": <StatusInfo message={message} />,
          }}
        />
      </Box>

      <Box sx={{ my: 4 }}>
        <Flex
          sx={{
            fontSize: 1,
            bg: "warning.500",
            borderRadius: 8,
            p: 2,
            mb: 3,
          }}
        >
          <IconWarning size={7} color="warning.800" />
          <Paragraph>
            {t(
              "Go to the MRETS dashboard, search with the serial prefix and look for the end serial match then retire through the quantity. The start serial may not be same in the MRETS while retiring but make sure the retirement is done for the same serial ranges through transaction page as mentioned in the tables below.",
            )}
          </Paragraph>
        </Flex>
        {!isEveryCommodityTransactionsProcessed && (
          <Flex
            sx={{
              fontSize: 1,
              bg: "primary.700",
              borderRadius: 8,
              p: 2,
              mb: 3,
              alignItems: "center",
            }}
          >
            <IconInfo size={5} />
            <Paragraph color="white">
              {t("Serial Number is loading. Please click on the refresh button to reload the page.")}
            </Paragraph>
          </Flex>
        )}
        <Table
          translation={getTableTranslation(t)}
          pagination={false}
          columns={columns}
          dataSource={formatRetirementReport(data ?? [], recOptions)}
          showScrollArrows
        />
      </Box>

      {allowRejection && (
        <Flex sx={{ justifyContent: "flex-end" }}>
          <Button variant="error" onClick={rejectRequest}>
            {t("Reject Request")}
          </Button>
        </Flex>
      )}
      <AttributesTooltip />
    </>
  );
};
