import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { ModalContext } from "@/app/components/modal-provider/modal-provider";
import { COMMODITY_TRANSACTION_FIELDS } from "@/app/graphql/fragments.graphql";
import { useRecOptions } from "@/app/hooks/use-rec-options";
import {
  CommoditySerial,
  CommodityTransactionStatus,
  RetirementStatus,
  useCompleteRetirementTransactionMutation,
  useRetirementByIdQuery,
} from "@/app/types/generated/graphql";

import { UseRetirementRequestDetailsFn } from "./retirement-request-details.types";

export const useRetirementRequestDetails: UseRetirementRequestDetailsFn = () => {
  const navigate = useNavigate();
  const retirementId = useParams().id;
  const { t } = useTranslation();
  const {
    data: queryData,
    loading: queryLoading,
    refetch,
  } = useRetirementByIdQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: retirementId,
    },
  });
  const [completeTransaction] = useCompleteRetirementTransactionMutation({
    update(cache, data) {
      if (data.data?.completeRetirementTransaction?.transactionId)
        cache.updateFragment(
          {
            id: `CommodityTransaction:${data.data.completeRetirementTransaction.transactionId}`,
            fragment: COMMODITY_TRANSACTION_FIELDS,
          },
          (data) => ({ ...data, status: CommodityTransactionStatus.Success }),
        );
    },
  });

  const { showConfirmationModal, showRejectRetirementRequestModal } = useContext(ModalContext);

  const { recOptions, loading: recOptionsLoading } = useRecOptions();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const refetchDetails = useCallback(() => {
    refetch();
  }, [refetch]);

  const markAsDone = useCallback(
    (requestId, requestSerial: CommoditySerial) => {
      const onSubmit = async () => {
        await completeTransaction({
          variables: {
            completeRetirementDetailInput: { commodityTransactionId: requestId },
          },
        });
      };
      showConfirmationModal({
        title: `${t(`Are you sure you want to complete retirement of serial`)} ${requestSerial.serialPrefix}-${
          requestSerial.serialFrom
        } ${t("to")} ${requestSerial.serialTo}?`,
        messages: {
          successMessage: t("Transaction marked as done"),
        },
        onSubmit,
      });
    },
    [showConfirmationModal, completeTransaction, t],
  );
  const rejectRequest = useCallback(() => {
    showRejectRetirementRequestModal({
      retirementId: queryData?.retirementById?.id ?? "",
      retirementNumber: queryData?.retirementById?.retirementNumber ?? "",
    });
  }, [queryData?.retirementById?.id, queryData?.retirementById?.retirementNumber, showRejectRetirementRequestModal]);

  const loading = queryLoading ?? recOptionsLoading;

  const data = queryData?.retirementById;

  const allowRejection =
    (data?.status === RetirementStatus.Requested &&
      data?.commodityDetails?.every(
        (detail) =>
          detail.status === RetirementStatus.Requested &&
          detail.commodityTransactions?.every((txn) => txn.status === CommodityTransactionStatus.Pending),
      )) ??
    false;

  return {
    data,
    loading,
    goBack,
    refetchDetails,
    recOptions,
    markAsDone,
    rejectRequest,
    allowRejection,
  };
};
